.section_technics {
    grid-template-areas: "B B B B A A A A A A A A" "B B B B C C C C C C C C";
    grid-gap: .5rem 2rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
}

.section_technics__cover {
    grid-area: A;
}

.section_technics__cover img {
    box-shadow: 0 0.5rem 2rem -1rem #00113366;
    border-radius: 0.25rem;
    width: 100%;
}

.section_technics__info {
    grid-area: B;
}

.section_technics__header {
    margin-left: -1rem;
    margin-right: -1rem;
}
.section_technics__header-item-1 {
    height: 170%;
    z-index: 11;
    top: -35%;
    left: -0.96em;
}
.section_technics:hover .section_technics__header-item-1 {
    left: 3.1em;
    transform: scale(.9);
}

.section_technics__info > p {
    position: relative;
    z-index: 100;
}

.section_technics__links {
    grid-area: C;
    font-size: 2rem;
}
.section_technics__links a {
    white-space: nowrap;
}

@media (max-width: 800px) {
    .section_technics {
        grid-template-areas: "A" "B" "C";
        grid-template-columns: 1fr;;
    }
    .section_technics__header {
        margin-left: 0;
        margin-right: 0;
    }
    .section_technics__links {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}