.footer {
    margin: 1rem auto;
    padding: 1rem;
    text-align: center;
}

.footer p:last-child {
    font-size: .85rem;
    margin-top: .5rem;
    color: rgb(163, 165, 182);
    font-weight: 300;
    font-style: italic;
}