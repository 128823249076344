.icon {
    display: inline;
    margin-right: .1em;
}

.icon img {
    height: 1em;
    width: auto;
    border: none;
    vertical-align: middle;
}

.icon_instagram {
    position: relative;
}

.icon_instagram:after {
    content: '*';
    display: block;
    position: absolute;
    right: -0.3em;
    top: 0;
}