.section_lection {
    grid-template-areas: 'A A A A A A A A A A A A';
    position: relative;
}
.section_lection__header {
    grid-area: A;
    bottom: 2rem;
    position: absolute;
    left: -1rem;
    font-size: 2rem;
    line-height: 2rem;
}
.section_lection__header-item-1 {
    width: 22%;
    left: 50%;
    top: -38%;
}
.section_lection:hover .section_lection__header-item-1 {
    left: 40%;
    top: 10%;
}
.section_lection__header-item-2 {
    width: 14.5%;
    right: -7%;
    bottom: -41%;
    z-index: 11;
}
.section_lection:hover .section_lection__header-item-2 {
    bottom: 56%;
    transform: rotate(135deg);
}

.section_lection__video {
    grid-area: A;
}

.section_lection__video video {
    transition: all ease .8s;
}
.section_lection:hover .section_lection__video video {
    filter: saturate(1.2) contrast(1.1) brightness(1.1) sepia(.2);
}

@media (max-width: 800px) {
    .section_lection {
        grid-template-areas: 'A' 'B';
        grid-template-columns: 1fr;
    }
    .section_lection__video {
        grid-area: A;
    }
    .section_lection__header {
        grid-area: B;
        position: relative;
        bottom: unset;
        margin-left: 0;
        left: 0;
        font-size: 1.25rem;
        line-height: 1.4rem;
    }
}
