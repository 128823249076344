* {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
}

html {
  font-size: 20px;
  font-family: 'Alegreya Sans', sans-serif;
}

@media (max-width: 800px) {
  html { font-size: 20px; }
}

@media (min-width: 1024px) {
  html { font-size: 20px; }
}
@media (min-width: 1200px) {
  html { font-size: 24px; }
}
@media (min-width: 1440px) {
  html { font-size: 28px; }
}
@media (min-width: 1600px) {
  html { font-size: 32px; }
}

img, video {
  max-width: 100%;
}

a {
  text-decoration: underline;
  text-decoration-thickness: 0.03em;
  text-decoration-color: #00000033;
  text-underline-position: from-font;
  color: #000000;
  transition: all .3s ease;
}
a:hover {
  color: #DD0000;
  text-decoration-color: #DD0000;
}

.section {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 0 2rem;
  margin: 3rem 2rem;
}

@media (max-width: 800px) {
  .section {
    margin: 2rem 1rem;
  }
}