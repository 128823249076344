.section_members {
    display: block;
}

.section_members__header {
    max-width: 5em;
    margin: 0 auto 2rem;
    text-align: center;
}
.section_members__header-item-1 {
    height: 120%;
    top: 10%;
    left: 20%;
}
.section_members:hover .section_members__header-item-1 {
    top: -40%;
    left: -10%;
}
.section_members__header-item-2 {
    height: 60%;
    top: -20%;
    left: 90%;
}
.section_members:hover .section_members__header-item-2 {
    top: 60%;
}

.section_members__list {
    display: grid;
    grid-template-areas:
        '. A A B B C C .'
        '. D D E E F F .';
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 1.5rem 2rem;
}

.section_members__member-img {
    /* border-radius: 50%; */
    width: 100%;
    transition: all .3s ease;
}

.section_members__member:nth-child(1) { grid-area: A; }
.section_members__member:nth-child(2) { grid-area: B; }
.section_members__member:nth-child(3) { grid-area: C; }
.section_members__member:nth-child(4) { grid-area: D; }
.section_members__member:nth-child(5) { grid-area: E; }
.section_members__member:nth-child(6) { grid-area: F; }

.section_members__member:hover .section_members__member-img {
    filter: sepia(.2) brightness(1.05);
}

.section_members__member-name {
    text-align: left;
    font-weight: 500;
}

.section_members__member-link {
    margin-top: .25rem;
}

.section_members__member-link .icon {
    margin-left: .2em;
}

@media (max-width: 800px) {
    .section_members__list {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas: 'A A B B' 'C C D D' 'E E F F';
    }
    .section_members__header {
        margin-bottom: 1.5rem;
    }
}