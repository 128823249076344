.section_channels {
    display: block;
    margin-top: 3.5rem;
    margin-bottom: 4rem;
}
.section_channels__header {
    width: 4em;
    margin: 0 auto 2rem;
    color: #000000;
}
.section_channels__header-item-1 {
    z-index: 11;
    height: 120%;
    top: -10%;
    left: 12%;
}
.section_channels:hover .section_channels__header-item-1 {
    left: 45%;
}
.section_channels__header-item-2 {
    z-index: 12;
    height: 40%;
    top: -32%;
    left: 40%;
}
.section_channels:hover .section_channels__header-item-2 {
    left: 75%;
    animation: channels-header-item-2 1s infinite;
}
@keyframes channels-header-item-2 {
    0% { top: -32%; }
    50% { top: -34%; }
    100% { top: -32%; }
}
.section_channels__list {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: 1fr 1fr 1fr;
}
.section_channels__item {
    text-decoration: none;
}
.section_channels__item-img {
    width: 100%;
    max-width: 4rem;
    height: auto;
    margin-left: -2%;
}
.section_channels__item-title {
    text-decoration: underline;
    text-decoration-thickness: .03em;
    text-decoration-color: #00000033;
    color: #000000;
    transition: all .3s ease;
    font-size: 1.2rem;
    font-weight: 500;
    align-self: self-end;
}
.section_channels__item:hover .section_channels__item-title {
    text-decoration-color: #DD0000;
    color: #DD0000;
}
.section_channels__item-description {
    color: #000000;
    margin-top: .5rem;
}

.section_channels__item .roles {
    margin-top: .5rem;
    color: #000000;
}

.section_channels__item .date {
    margin-top: -.25rem;
}

@media (max-width: 800px) {
    .section_channels {
        margin-top: 2.75rem;
        margin-bottom: 2.25rem;
    }
    .section_channels__list {
        grid-template-columns: 1fr;
        grid-template-areas: 'A';
        grid-gap: 1.25rem 1rem;
    }
    .section_channels__header {
        margin-bottom: 1rem;
        margin-left: 0;
        margin-right: 0;
    }
    .section_channels__item {
        grid-template-columns: 1fr 5fr;
        grid-gap: .25rem .75rem;
    }
}