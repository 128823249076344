.logo {
    padding: 1rem;
    margin: 0 auto;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.logo__text {
    text-align: center;
    line-height: 1;
    font-size: 2.5rem;
    font-family: 'Alegreya Sans SC', 'Alegreya', sans-serif;
    font-weight: 500;
    transition: all .8s ease;
}
.logo:hover .logo__text {
    letter-spacing: .05em;
}

.logo__sign {
    position: relative;
    margin: 0 auto;
    width: 48%;
    transition: all .8s ease;
    margin: 1.25rem auto 1.25rem
}
.logo:hover .logo__sign {
    transform: scale(1.05);
}


.logo__sign img {
    transition: all .8s ease;
}
.logo__sign img:nth-child(1) {
    position: absolute;
    transform: rotate(45deg);
    width: 100%;
}
.logo:hover .logo__sign img:nth-child(1) {
    transform: rotate(-45deg);
}
.logo__sign img:nth-child(2) {
    position: relative;
    width: 100%;
}
.logo:hover .logo__sign img:nth-child(2) {
    transform: rotate(90deg);
}
.logo__sign img:nth-child(3) {
    position: absolute;
    top: 14.75%;
    left: 14.75%;
    transform: rotate(45deg);
    width: 70.5%;
}
.logo:hover .logo__sign img:nth-child(3) {
    transform: rotate(-45deg);
}
.logo__sign img:nth-child(4) {
    position: absolute;
    top: 20.5%;
    left: 20.5%;
    width: 59%;
}
.logo:hover .logo__sign img:nth-child(4) {
    transform: rotate(180deg);
}
.logo__sign img:nth-child(5) {
    position: absolute;
    top: 30%;
    left: 30%;
    width: 40%;
}
.logo:hover .logo__sign img:nth-child(5) {
    transform: rotate(360deg);
}



@media (max-width: 800px) {
    .logo .logo__sign {
        transform: scale(1.4);
    }
    .logo:hover .logo__sign {
        transform: scale(1.5);
    }
}