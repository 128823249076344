.section__header {
    position: relative;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: #ffffff;
    height: fit-content;
    max-width: 16em;
    padding: 0.75rem 1rem 1rem;
    font-weight: 500;
}
.section__header-soon {
    font-style: italic;
    color: #000000;
    font-weight: 400;
}
.section__header-text {
    position: relative;
    z-index: 100;
}
.section__header-background {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: 0;
}
.section__header > *:not(.section__header-text):not(.section__header-background) {
    position: absolute;
    transition: all 1s ease;
}

@media (max-width: 800px) {
    .section__header > *:not(.section__header-text) {
        display: none;
    }
    .section__header {
        color: #000000;
        padding: 0;
        margin-top: .5rem;
        font-size: 2rem;
        line-height: 2.25rem;
    }
    .section__header-soon {
        color: #dd0000;
    }

}