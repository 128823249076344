.section__good-news {
    grid-template-areas: "A A A A A A A A B B B B" "C C C C C C C C B B B B";
}

.section_good-news__cover {
    grid-area: A;
}

.section_good-news__info {
    grid-area: B;
}
.section_good-news__header {
    line-height: 2rem;
    margin-left: -1rem;
}
.section_good-news__header-item-1 {
    width: 30%;
    right: 17%;
    top: -22%;
    z-index: 11;
}
.section__good-news:hover .section_good-news__header-item-1 {
    right: -14%;
    top: -37%;
    transform: rotate(135deg);
}


.section_good-news__links {
    grid-area: C;
    font-size: 2rem;
}
.section_good-news__links a {
    white-space: nowrap;
}

@media (max-width: 800px) {
    .section__good-news {
        grid-template-columns: 1fr;
        grid-template-areas: "A" "B" "C";
    }
    .section_good-news__header {
        margin-left: 0;
    }
    .section_good-news__links {
        font-size: 1.25rem;
        line-height: 1.5rem;
    }
}